import React from 'react'
import './RecordJobInformation.css';

function RecordJobInformation(props) {
    return (
        <>
            <div className='record-job-information'>
                <div> ثبت اطلاعات شغلی </div>
            </div>

            <div style={{ width: '100%', height: '1px', backgroundColor: 'var(--Divider-Background-Color)', marginBottom: '32px', marginTop: '16px' }}></div>

        </>
    )
}

export default RecordJobInformation