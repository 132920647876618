import ADS from '../../../Assets/Supplier/ADS.svg';
import Aim from '../../../Assets/Supplier/Aim.svg';
import Welcome from '../../../Assets/Supplier/Welcome.svg';

const Description1 = () => {
    return (
        <>
            فروش مستقیم به مصرف کننده یکی از بزرگترین مزایایEliteZone  برای سوپلایرها (تأمین‌کنندگان ممتاز) است. در این پلتفرم، سوپلایرها می‌توانند محصولات خود را به دست خریداران واقعی برسانند. این امر علاوه بر
            <strong> کاهش هزینه‌ها و افزایش حاشیه </strong>
            سود، موجب ایجاد ارتباط مستقیم و مؤثر میان سوپلایرها و خریداران می‌شود که می‌تواند به بهبود روابط تجاری منجر گردد.
        </>
    )
}

const Description2 = () => {
    return (
        <>
            حضور در EliteZone  به سوپلایرها کمک می‌کند تا شناخت بیشتری از برند خود در میان فعالان صنعت ساختمان ایجاد کنند. این پلتفرم به عنوان یک مرجع معتبر در انتخاب مصالح ساختمانی شناخته می‌شود و تأمین‌کنندگان حاضر در آن، به مرور زمان به نام‌های قابل اعتماد در بازار تبدیل می‌شوند. این فرصت برای
            <strong> توسعه کسب‌وکار و افزایش سهم بازار </strong>
            بسیار ارزشمند است.

        </>
    )
}

const Description3 = () => {
    return (
        <>
            اینجا بهشت سوپلایرها (تامین‌کنندگان ممتاز) است. ما در EliteZone  به جای کار با برندهای متعدد، تنها محصولات با کیفیت برتر را انتخاب می‌کنیم؛ محصولاتی که شایسته جامعه مهندسی ایران باشند. اولویت با تامین‌کنندگانی است که پیشگام همکاری با ما هستند و یکی از ارزش های گروه نرم افزاری سازه وفاداری به شرکای کاری است.
            همین امروز با <a href="/auth" style={{ color: 'var(--Primary-EZ)', cursor: 'pointer', textDecoration: 'none' }}> ثبت‌نام زودهنگام در EliteZone </a>
            ،
            نشان Pioneer را دریافت کرده و محصولات خود را در اولویت عرضه قرار دهید. پس از ثبت‌نام، همکاران ما جهت ساخت پنل اختصاصی محصولات با شما تماس خواهند گرفت. به دنیای نوین بازاریابی هیبریدی EliteZone  خوش آمدید!

        </>
    )
}

export const SupplierMoreDetail = [
    {
        Img: Aim,
        Tilte: 'مستقیم به هدف!',
        Description: Description1()
    },
    {
        Img: ADS,
        Tilte: 'برند من مهم است!',
        Description: Description2()
    },
    {
        Img: Welcome,
        Tilte: 'EliteZone  با افتخار پذیرای حضور شماست!',
        Description: Description3()
    },

]