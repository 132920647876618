import React, { useState } from 'react';
import './FAQ.css';
import AccordinBox from '../../../PersonalFunction/AccordinBox';
import Flash2_TextEZ02_Icon from '../../../PublicAssets/Arrow_Icons/Flash2_TextEZ02_Icon.svg';
import { AnimatePresence, motion } from 'framer-motion';

function FAQ() {

    const Answer4 = () => {
        return (
            <>
                مهندسین و پیمانکارانی که در EliteZone  ثبت نام می کنند پس از ورود به سامانه از طریق پروفایل خود به اطلاعات فنی و قیمت های مصالح ساختمانی دسترسی پیدا می کنند. پس از آن هنگام مراجعه کارفرما به دفتر مهندسی یا به هر نحو که صلاح می دانند، با ارائه توضیحات فنی لازم خرید مصالح ساختمانی موجود را به کارفرما پیشنهاد داده و مشخصات کارفرما را در پنل خود درج می کنند.
                از سوی دیگر هنگامی که کارفرما قصد خرید مصالح ساختمانی از EliteZone را دارد، برای استفاده از قیمت های ویژه، لازم است یک مهندس یا پیمانکار را به عنوان مشاور انتخاب کند و با توجه به اینکه قبلا مشخصات کارفرما در پنل مهندس یا پیمانکار ثبت شده است، همین مهندس یا پیمانکار در پنل کارفرما به عنوان مشاور به او معرفی می شود تا با انتخاب او بتواند از قیمت های ویژه الیت زون استفاده کند.
                درصورت انجام فرایند خرید توسط کارفرما، مهندس یا پیمانکاری که هنگام خرید توسط کارفرما به عنوان مشاور انتخاب می شود، هزینه مشاوره خود را مستقیما از طریق EliteZone دریافت می کند.
            </>
        )
    }

    // const Answer8 = () => {
    //     return (
    //         <>
    //             پس از انجام
    //             <a href="/auth" style={{ color: 'var(--Primary-EZ)', cursor: 'pointer', textDecoration: 'none' }}> ثبت نام </a>
    //             در پروفایل مهندسان و پیمانکاران بخشی تحت عنوان پنل خرید مصالح ساختمانی در دسترس قرار می گیرد. همه فرایند خرید از طریق این پنل انجام می شود.
    //         </>
    //     )
    // }

    // const Answer9 = () => {
    //     return (
    //         <>
    //             با توجه به جزئیات تخصصی، لازم است پنل ویژه فروش برای هر نوع مصالح ساختمانی به صورت جداگانه طراحی گردد. پس از
    //             <a href="/auth" style={{ color: 'var(--Primary-EZ)', cursor: 'pointer', textDecoration: 'none' }}> ثبت نام </a>
    //             سوپلایرها، برای طراحی پنل تخصصی فروشِ ویژه با شما تماس می گیریم تا جزئیات مورد نظرتان در پنل مربوطه لحاظ گردد. پس از آن می توانید محصولات خود را از طریق این پنل در EliteZone به جامعه مهندسان و پیمانکاران ایران عرضه نمائید.
    //         </>
    //     )
    // }

    // const Answer10 = () => {
    //     return (
    //         <>
    //             کافیست کارفرمایان محترم برای خرید مصالح ساختمانی مورد نظر خود با مهندسان یا پیمانکاران پروژه ها تماس بگیرند و فرایند خرید خود را از طریق آنان انجام دهند. اطلاعات کافی در خصوص مصالح ساختمانی مورد نظر و قیمت ها از طریق مهندسان یا پیمانکاران در اختیار شما قرار می گیرد. چنانچه در شهر خود مهندس یا پیمانکار نمی شناسید، ما می توانیم فهرستی از مهندسان یا پیمانکاران شهر شما در اختیارتان قرار بدهیم. این فهرست پس از
    //             <a href="/auth" style={{ color: 'var(--Primary-EZ)', cursor: 'pointer', textDecoration: 'none' }}> ثبت نام </a>
    //             از طریق پنل ویژه شما در اختیارتان قرار می گیرد.
    //         </>
    //     )
    // }

    const Questions = [
        {
            id: 1,
            question: 'EliteZone چیست؟',
            answer: 'EliteZone یک سامانه آنلاین در خصوص خرید مصالح ساختمانی استاندارد است که با استفاده از پتانسیل مهندسان و پیمانکاران برای مشاوره به کارفرمایان، بازار بسیار بزرگی برای سوپلایرها (تامین کنندگان ممتاز) ایجاد می کند. این موضوع منجر به تامین مصالح ساختمانی با کیفیت بالا و قیمت رقابتی برای کارفرمایان، کاهش هزینه های بازاریابی برای سوپلایرها، و درآمد برای مهندسان و پیمانکاران می گردد.'
        },
        {
            id: 2,
            question: 'چه چیز موفقیت عملکرد EliteZone را تضمین می کند؟',
            answer: 'بنیانگذار EliteZone، گروه نرم افزاری سازه است. از سه دهه قبل، تقریبا تمامی پروژه های ساختمانی ایران توسط محصولات این گروه خصوصا نرم افزار SAZE90 طراحی می شود. این موضوع علاوه بر ایجاد اعتبار برای این گروه نزد جامعه مهندسان و پیمانکاران، دسترسی به همه بازار ساختمان ایران را میسر می سازد. این بازار بسیار بزرگ، تامین کنندگان معتبر را جذب و به این ترتیب چرخه استفاده از مصالح ساختمانی استاندارد تکمیل و موفقیت EliteZone تضمین می شود.'
        },
        {
            id: 3,
            question: 'حجم پروژه های مرتبط با نرم افزار SAZE90 چقدر است؟',
            answer: 'حجم پروژه های مرتبط با نرم افزار SAZE90 در سال بالغ بر ده ها میلیون مترمربع است که بازار بسیار بزرگی را برای سوپلایرها(تامین کنندگان معتبر) تعریف می کند. برنامه ریزی های انجام شده برای EliteZone همه پروژه های ساختمانی ایران را هدف قرار داده است.'
        },
        {
            id: 4,
            question: 'فرآیند خرید مصالح ساختمانی چگونه انجام می شود؟',
            answer: Answer4()
        },
        {
            id: 5,
            question: 'چرا یک مهندس یا یک پیمانکار از EliteZone استفاده می کند؟',
            answer: 'مهندسان یا پیمانکاران ذیصلاح بخوبی می دانند که ساختمان هائی که با استفاده از مصالح ساختمانی استاندارد و با کیفیت ساخته می شوند می تواند مسئولیت حرفه ای آنها در مقابل پروژه ها را پوشش دهد. همچنین مهندسان و پیمانکاران ذیصلاح، بخش مهمی از جامعه فرهیخته ایران هستند که انگیزه کافی برای کار بیشتر و کسب درآمد بیشتر دارند. EliteZone فرصت بی نظیری برای جامعه مهندسی ایران فراهم می آورد که از طریق آن بتوانند به مسئولیت های خود در قبال ساختمان ها بخوبی عمل کرده و در مقابل درآمد خود را به میزان قابل توجه افزایش دهند. مسیری که هیچگاه قبلا میسر نبوده است.'
        },
        {
            id: 6,
            question: 'چرا یک سوپلایر (تامین کننده ممتاز) از EliteZone استفاده می کند؟',
            answer: 'پس از فرایندهای تولید، تامین کنندگان همواره به دنبال بازاریابی موثر برای محصولات خود هستند و در این رابطه به میزان قابل توجهی هزینه می کنند. اگر یک تامین کننده معتبر مبتنی بر شواهد قابل اثبات بداند که به بازاری به بزرگی همه ایران دسترسی پیدا کرده است، حتما برای به دست آوردن چنین موقعیتی درنگ نخواهد کرد. بر اساس شواهد قابل ارائه، گروه نرم افزاری سازه ادعا می نماید که از طریق کاربران نرم افزارهای خود هم اکنون چنین بازاری را در اختیار دارد. لذا اقبال سوپلایرها به این بازار قطعی است.'
        },
        {
            id: 7,
            question: 'چرا یک کارفرما از EliteZone استفاده می کند؟',
            answer: 'کارفرمایان برای دریافت خدمات مهندسی و همچنین ساخت پروژه های خود مستقیما با مهندسان و پیمانکاران ذیصلاح در تماس هستند و از آنها در این رابطه مشورت می گیرند. همچنین کارفرمایان مایلند در ساختمان های خود از مصالح با کیفیت با قیمت های رقابتی استفاده کنند. EliteZone چنین امکانی را فراهم کرده است. کافیست مصالح ساختمانی ارائه شده در این سامانه توسط مهندسان و پیمانکاران برای خرید پیشنهاد شده و دلائل فنی و اقتصادی این خرید برای کارفرمایان محترم توجیه گردد. طبیعتاً خرید از این سامانه از هم از جهت کیفیت، هم از جهت قیمت و هم به دلیل اعتماد به مهندس یا پیمانکار پروژه برای کارفرما کاملا قابل توجیه است.'
        },
        // {
        //     id: 8,
        //     question: 'چگونه یک مهندس یا یک پیمانکار می تواند از EliteZone برای سفارش مصالح ساختمانی استفاده کند؟',
        //     answer: Answer8()
        // },
        // {
        //     id: 9,
        //     question: 'چگونه یک سوپلایر (تامین کننده ممتاز) برای ارائه محصولات خود از EliteZone استفاده می کند؟',
        //     answer: Answer9()
        // },
        // {
        //     id: 10,
        //     question: 'چگونه یک کارفرما برای خرید محصولات مورد نظر از EliteZone استفاده می کند؟',
        //     answer: Answer10()
        // }
    ];

    const [ShowAllQuestion, setShowAllQuestion] = useState(false);

    const displayedQuestion = ShowAllQuestion ? Questions : Questions.slice(0, 5)

    const HandleDisplayedQuestion = () => {
        setShowAllQuestion(!ShowAllQuestion)
    }

    return (
        <div className='faq-container A_Style'>
            <div className='faq-content-container B_Style'>
                <div className='faq-content-wrapper'>
                    <div className='faq-title'>
                        <div>سوالات متداول</div>
                        <div> شما پرسیده اید... </div>
                    </div>

                    <AnimatePresence>
                        {displayedQuestion.map((question, index) => (
                            <motion.span key={index}
                                style={{ color: 'var(--Primary-EZ)', cursor: 'pointer' }}
                                initial={{ height: 0, opacity: 0 }}
                                animate={{ height: 'auto', opacity: 1 }}
                                exit={{ height: 0, opacity: 0 }}
                                transition={{ duration: .3, type: 'tween' }}
                            >
                                <AccordinBox question={question} index={index} />
                            </motion.span>
                        ))}
                    </AnimatePresence>

                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px', }}>
                        {Questions.length > 5 &&
                            // <ShortenerButton expanded={ShowAllQuestion} toggleExpand={HandleDisplayedQuestion} />
                            <button className='NotifShowMoreButton' onClick={HandleDisplayedQuestion}>
                                {ShowAllQuestion ?
                                    <div style={{ display: 'flex', alignItems: 'center', direction: 'rtl' }}>
                                        <div> بستن </div>
                                        <div style={{ display: 'inline-flex', marginRight: '8px', rotate: '90deg' }}> <img src={Flash2_TextEZ02_Icon} alt='img' style={{width: '8px'}} /> </div>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', alignItems: 'center', direction: 'rtl' }}>
                                        <div> نمایش همه سوالات متداول </div>
                                        <div style={{ display: 'inline-flex', marginRight: '8px', rotate: '-90deg' }}> <img src={Flash2_TextEZ02_Icon} alt='img' style={{width: '8px'}} /> </div>
                                    </div>
                                }
                            </button>
                        }
                    </div>

                </div>
            </div>
        </div>
    );
}

export default FAQ;
