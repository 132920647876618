import React from 'react'
import AccordinBox from '../../../PersonalFunction/AccordinBox'

function SupplierImplementationProcess() {

    const Questions = [
        {
          id: 1,
          question: ' 1. ثبت‌نام و آشنایی با سیستم',
          answer:
            <>
              <strong >ثبت‌نام در سیستم: </strong>
              در EliteZone  ثبت‌نام کنید و اطلاعات حساب کاربری و اطلاعات شغلی خود را تکمیل نمائید.
              آماده مذاکره با همکاران EliteZone در خصوص نحوه تعامل با این پلتفرم باشید.
              <br />
              <br />
              <strong>  آموزش سیستم: </strong>
              با نحوه استفاده از پلتفرم، از جمله مدیریت سفارش‌ها، موارد مالی، و سیستم گزارش‌دهی آشنا شوید.
            </>
        },
        {
          id: 2,
          question: ' 2. ارائه اطلاعات دقیق محصولات',
          answer:
            <>
              <strong>  ثبت مشخصات محصولات:  </strong>
              تمامی محصولات ارائه‌شده را با مشخصات دقیق شامل توضیحات فنی، استانداردها، قیمت‌ها و شرایط تحویل در پلتفرم ثبت کنید.
              <br />
              <br />
              <strong> به‌روزرسانی اطلاعات: </strong>
              قیمت‌ها و موجودی کالاها باید به‌صورت منظم به‌روز شوند تا از بروز مشکلات در ثبت سفارش جلوگیری شود.        </>
        },
        {
          id: 3,
          question: ' 3. دریافت و مدیریت سفارش‌ها',
          answer:
            <>
              <strong> دریافت سفارش: </strong>
              درخواست‌های خرید را از طریق سیستم دریافت کرده و جزئیات آن‌ها را بررسی کنید.
              <br />
              <br />
              <strong> تایید سفارش: </strong>
              سفارش‌ها را پس از بررسی موجودی و زمان‌بندی تحویل تایید کرده و جزئیات را به خریدار اطلاع دهید.          <br />
            </>
        },
        {
          id: 4,
          question: ' 4. تامین و ارسال مصالح',
          answer:
            <>
              <strong> آماده‌سازی مصالح </strong>
              محصولات سفارش‌داده‌شده را مطابق با مشخصات موردنیاز آماده کنید.
              <br />
              <br />
              <strong> تحویل به موقع:  </strong>
              اطمینان حاصل کنید که مصالح در زمان تعیین‌شده و مطابق با شرایط توافق‌شده به محل پروژه تحویل داده شوند.
              <br />
              <br />
              <strong> بسته‌بندی و حمل و نقل:  </strong>
              مصالح باید به‌صورت ایمن بسته‌بندی شده و شرایط حمل و نقل استاندارد رعایت شود.
            </>
        },
        {
          id: 5,
          question: ' 5. تضمین کیفیت',
          answer:
            <>
              <strong>  کنترل کیفیت: </strong>
              محصولات باید قبل از تحویل مورد بررسی قرار گیرند تا مطابق با استانداردهای اعلام‌شده باشند.
              <br />
              <br />
              <strong>  ارائه گواهینامه‌ها:  </strong>
              در صورت نیاز، گواهینامه‌های مرتبط با کیفیت و استانداردهای محصول را ارائه کنید.
            </>
        },
        {
          id: 6,
          question: ' 6. پشتیبانی و خدمات پس از فروش',
          answer:
            <>
              <strong> پاسخگویی به سوالات:  </strong>
              به سوالات و مشکلات احتمالی مهندسان، پیمانکاران یا کارفرمایان در مورد محصولات پاسخ دهید.
              <br />
              <br />
              <strong> رسیدگی به شکایات:  </strong>
              در صورت وجود شکایات، آن‌ها را به سرعت بررسی کرده و اقدامات لازم برای رفع آن‌ها را انجام دهید.
            </>
        },
        {
          id: 7,
          question: ' 7. مدیریت مالی و صدور فاکتور',
          answer:
            <>
              <strong>  صدور فاکتور رسمی:  </strong>
              برای هر سفارش، فاکتور رسمی مطابق با جزئیات سفارش صادر کنید.
              <br />
              <br />
              <strong>  پیگیری پرداخت‌ها: </strong>
              وضعیت مالی سفارش را پیگیری کرده و پرداخت‌های انجام شده را مطابق توافق بررسی کنید.
            </>
        },
        {
          id: 8,
          question: ' 8. ارزیابی و بهبود عملکرد',
          answer:
            <>
              <strong> جمع‌آوری بازخورد:  </strong>
              بازخوردهای دریافتی از مهندسان، پیمانکاران و کارفرمایان را بررسی کرده و برای بهبود کیفیت خدمات از آن‌ها استفاده کنید.
              <br />
              <br />
              <strong>  به‌روزرسانی خدمات:  </strong>
              خدمات خود را با نیازهای بازار و پروژه‌ها تطبیق دهد.
            </>
        },
        {
          id: 9,
          question: 'نکات مهم',
          answer:
            <>
              <strong>  رعایت اخلاق حرفه‌ای:  </strong>
              تعامل شفاف، منصفانه و حرفه‌ای با مشتریان بسیار مهم است.
              <br />
              <br />
              <strong>  تعهد به زمان‌بندی:  </strong>
              تاخیر در تحویل مصالح می‌تواند پروژه را مختل کند؛ بنابراین، رعایت زمان‌بندی ضروری است.
              <br />
              <br />
              <strong>  شفافیت در قیمت‌گذاری:  </strong>
              قیمت‌ها باید شفاف و رقابتی باشند و از هرگونه هزینه اضافی یا تغییرات غیرمنتظره جلوگیری شود.
              <br />
              <br />
              این دستورالعمل می‌تواند بر اساس نیازهای خاص EliteZone   تکمیل شود.
            </>
        },
      ];
      
    return (
        <>
            <div className='ImplementationProcess_Container'>
                <div className='title'>
                    فرآیند اجرایی سوپلایرها
                </div>
                در EliteZone، تامین‌کنندگان (سوپلایرها) نقشی مهم در ارائه مصالح ساختمانی با کیفیت و به موقع دارند. دستورالعمل زیر به منظور تعریف وظایف سوپلایرها و نحوه انجام این وظایف تهیه شده است

                <div className='moredetail'>
                    {Questions.map((question, index) => (
                        <span key={index}>
                            <AccordinBox question={question} index={index} />
                        </span>
                    ))}
                </div>
            </div>
        </>
    )
}

export default SupplierImplementationProcess