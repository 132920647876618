const ConceptMainContent = `
EliteZone منطقه نخبگان صنعت ساختمان ایران است! مهندسان و پیمانکاران از طریق این سامانه سوپلایرها (تأمین‌کنندگان ممتاز) را به کارفرمایان وصل می کنند تا کل بازار مصالح ساختمانی ایران اینجا شکل بگیرد. تنها در سال 1403 مساحت پروژه های در دسترس در EliteZone از طریق مهندسانِ کاربر نرم افزار SAZE90
<strong>
ده ها میلیون مترمربع
  </strong>
 است!
<br />
در EliteZone همه برنده اند. مهندسان و پیمانکاران با استفاده از ارتباطات و توان تکنیکال خود
<strong>
درآمدهای قابل توجه
</strong>  
کسب می کنند، سوپلایرها به 
  <strong>
همه بازارِ ساختمان ایران
 </strong>
دسترسی پیدا می کنند، و کارفرمایان مصالح ساختمانی مورد نیاز خود را با 
<strong>
کیفیت مهندسی و قیمت رقابتی 
</strong>
 خریداری می کنند.
<br />
اگر مهندس، پیمانکار، سوپلایر، و یا کارفرما هستید با
  <a href="/auth" style="color: var(--Primary-EZ); text-decoration: none;">
  ثبت نام زودهنگام در EliteZone
</a>
نشان Pioneer دریافت کنید و درآمد یا منافع خود را به نحو قابل توجه افزایش دهید!

`;

export default ConceptMainContent;