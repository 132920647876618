import React from 'react'
import './Rules.css';
import Navbar from '../../Home/Components/Navbar';
import TermsImg from '../Assets/Terms.svg';
import AccordinBox from '../../../PersonalFunction/AccordinBox';

function Terms() {

    const Questions = [
        {
            id: 1,
            question: 'قوانین عمومی',
            answer: 'ورود به وب‌سایت اِلیت زون و استفاده از امکاناتی چون پروفایل شخصی، پنل های کاربری، ویدئوهای تصویری و سایر خدمات ارائه‌شده، نشان‌دهنده‌ی آگاهی و پذیرش قوانین و مقررات و نحوه استفاده از خدمات اِلیت زون است. به یاد همچنین، توجه داشته باشید که این شرایط و ضوابط، جایگزین هرگونه توافق قبلی خواهد بود و تمامی کاربران ملزم به رعایت آن هستند.'
        },
        {
            id: 2,
            question: 'تعریف کاربر',
            answer: 'کاربر به فردی اطلاق می‌شود که با استفاده از اطلاعات کاربری خود که در فرم ثبت‌نام وارد کرده است، به وبسایت ورود نماید و از هر یک از خدمات اِلیت زون استفاده کند. با توجه به اینکه اِلیت زون یک وبسایت تخصصی در زمینه محصولات مهندسی ساختمان است کلیه کاربران پیش از ورود می پذیرند که در این صنعت مشغول به کار هستند و نقش خود را به عنوان مهندس، پیمانکار، سوپلایر (تامین کننده)، و کارفرما تعریف می نمایند. کاربران موافقت می نمایند در صورت تقاضای اِلیت زون برای احراز نسبت کاربران به صنعت ساختمان، مدارک لازم را ارائه نمایند. الیت زون مجاز است از ثبت نام یا ورود یا استفاده از امکانات وبسایت در خصوص کاربران نامرتبط با صنعت ساختمان جلوگیری نماید.'
        },
        {
            id: 3,
            question: 'ارتباطات الکترونیکی',
            answer: (
                <>
                    هنگامی که شما از خدمات و سرویس‌های اِلیت زون استفاده می‌کنید یا پیامی به اِلیت زون ارسال می‌کنید، این ارتباط به‌صورت الکترونیکی انجام می‌شود. با انجام این کار، شما تأیید می‌کنید که اِلیت زون مجاز است به درخواست شما از طریق ایمیل، پیامک یا سایر ابزارهای الکترونیکی پاسخ دهد، مشروط بر اینکه درخواست شما مطابق با قوانین و رویه‌ها باشد. آدرس ایمیل و شماره تلفن‌هایی که در پروفایل خود ثبت می‌کنید، تنها راه‌های رسمی ارتباطی با شما خواهند بود، و تمامی مکاتبات اِلیت زون به همین آدرس‌ها و شماره‌ها ارسال می‌شود.
                    <br />
                    لطفاً توجه داشته باشید که فقط شماره‌های مشخص و تأییدشده‌ای از سوی اِلیت زون برای ارسال پیامک به مشتریان مورد استفاده قرار می‌گیرند. از این شماره‌ها تنها برای اطلاع‌رسانی وضعیت سفارش، خدمات یا رویدادهای ویژه استفاده می‌شود و امکان پاسخ‌دهی به آن‌ها وجود ندارد.
                </>
            )
        },
        {
            id: 4,
            question: 'سیاست‌های حفظ حریم خصوصی',
            answer: (
                <>
                    اِلیت زون به حریم خصوصی و امنیت اطلاعات شخصی کاربران خود اهمیت می‌دهد و تمام اقدامات لازم را برای حفاظت از این اطلاعات به‌کار می‌گیرد. این مجموعه متعهد است که در حد امکان با بهره‌گیری از فناوری‌های پیشرفته، فضایی امن و مطمئن برای استفاده کاربران فراهم نماید. استفاده شما از خدمات اِلیت زون نشان‌دهنده‌ی پذیرش کامل این سیاست‌ها و رضایت از نحوه پردازش اطلاعات شخصی توسط این مجموعه است.
                    <br />
                    تمامی محتوای موجود در وب‌سایت اِلیت زون، از جمله متن‌ها، تصاویر، ویدئوها، آیکون‌ها، گرافیک‌ها و دیگر محتواها، به‌عنوان دارایی اختصاصی اِلیت زون به حساب می‌آید. حق نشر، بهره‌برداری و توزیع این محتوا صرفاً در اختیار اِلیت زون بوده و هرگونه استفاده‌ی غیرمجاز از این محتوا پیگرد قانونی دارد. همچنین، نام‌ها و نشانه‌های تجاری، اسکریپت‌ها و خدمات وب‌سایت نیز در انحصار کامل اِلیت زون قرار دارند و استفاده از آن‌ها برای اهداف تجاری بدون کسب مجوز صریح از این مجموعه ممنوع است.
                    <br />
                    کاربران مجاز نیستند از داده‌ها و اطلاعات وب‌سایت اِلیت زون، مانند لیست محصولات، ویژگی‌های فنی، قیمت‌ها یا هرگونه محتوای مشابه برای اهداف تجاری، داده‌کاوی، استفاده از ربات‌ها و یا هر روش جمع‌آوری داده بدون مجوز رسمی استفاده کنند. تمامی این حقوق به‌طور کامل برای اِلیت زون محفوظ است.
                    <br />
                    مسئولیت حفظ و نگهداری از اطلاعات حساب کاربری و رمز عبور بر عهده کاربران است و هرگونه فعالیت صورت‌گرفته از طریق این حساب‌ها تحت مسئولیت کاربر خواهد بود. کاربران زیر ۱۸ سال نمی توانند در این وبسایت فعالیت نمایند.
                    <br />
                    وب‌سایت رسمی اِلیت زون به نشانی www.elitezone.me  تنها مرجع رسمی برای ارتباط با کاربران و اطلاع‌رسانی است. هیچ آدرس وب‌سایت دیگری به‌جز آدرس مذکور از سوی اِلیت زون برای برقراری ارتباط استفاده نمی‌شود و هرگونه پیام، ایمیل یا ارتباط دیگر خارج از این کانال‌ها غیرمعتبر است. برای اطمینان از صحت ارتباطات، کاربران می‌توانند تنها از طریق اطلاعات تماس موجود در بخش «تماس با ما» در سایت اِلیت زون اقدام کنند.
                </>
            )
        },
        {
            id: 5,
            question: 'نحوه توسعه وبسایت',
            answer: 'کاربران با عضویت در اِلیت زون توافق می نمایند نحوه توسعه و بسایت وسیاست های اعمال شده در کارکردهای اِلیت زون اعم از نوع خدمات ارائه شده، زمان انتشار، نحوه انتشار مطالب، و سایر موارد مرتبط  تنها از طریق اِلیت زون انجام می گردد و کاربران با عضویت حقوق اِلیت زون در این رابطه را می پذیرند.'
        },
    ];

    return (
        <>
            <Navbar />

            <div className='Terms_Container'>

                <div className='Terms_Img'>
                    <img src={TermsImg} alt='img' />
                </div>

                <div className='Terms_Title'>
                    شرایط و ضوابط استفاده از خدمات اِلیت زون
                </div>

                <div className='Terms_Body'>

                    <div className='Terms_Body_Text'>
                        کاربر گرامی، برای بهره‌برداری بهتر از خدمات و برنامه‌های اِلیت زون، لطفاً موارد زیر را با دقت مطالعه کنید.
                        <br />
                        ورود به وب‌سایت اِلیت زون و استفاده از امکاناتی چون پروفایل شخصی، پنل های کاربری، ویدئوهای تصویری و سایر خدمات ارائه‌شده، نشان‌دهنده‌ی آگاهی و پذیرش قوانین و مقررات و نحوه استفاده از خدمات اِلیت زون است. همچنین، توجه داشته باشید که این شرایط و ضوابط، جایگزین هرگونه توافق قبلی خواهد بود و تمامی کاربران ملزم به رعایت آن هستند.
                    </div>

                    <div className='Terms_Body_QuestionBox'>
                        {Questions.map((question, index) => (
                            <span key={index}>
                                <AccordinBox question={question} index={index} />
                            </span>
                        ))}
                    </div>

                </div>

            </div>
        </>
    )
}

export default Terms