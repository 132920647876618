import gold from '../../../Assets/Badges/WorkingTogether/Gold_WorkingTogether.png';
import silver from '../../../Assets/Badges/WorkingTogether/Silver_WorkingTogether.png';
import bronze from '../../../Assets/Badges/WorkingTogether/Bronze_WorkingTogether.png';

export const WorkingTogether = [

    { Img: gold, name: 'gold', Title: 'نشان طلایی سطح همکاری', Description: 'تبریک! شما این نشان را دریافت کرده‌اید.' },

    { Img: silver, name: 'silver', Title: 'نشان نقره ایی سطح همکاری', Description: 'تبریک! شما این نشان را دریافت کرده‌اید.' },

    { Img: bronze, name: 'bronze', Title: 'نشان برنزیی سطح همکاریی', Description: 'تبریک! شما این نشان را دریافت کرده‌اید.' },

]