const EngineerMainContent =
`
فرصت ممتاز خرید مصالح ساختمانی با کیفیت بالا و قیمت رقابتی از
EliteZone  
برای کارفرمایان تنها از
<strong>طریق معرفی مهندسان و پیمانکاران احراز شده</strong>
میسر است. اگر مهندس هستید با
<a href="/auth" style="color: var(--Primary-EZ); text-decoration: none;">
ثبت نام زودهنگام در EliteZone
</a>
نشان Pioneer  دریافت کنید، با استفاده از پنل ویژه مهندسان به کارفرمایان در خرید مصالح ساختمانی کمک کنید، و درآمد خود را به نحو قابل توجه افزایش دهید!
`;

export default EngineerMainContent;
