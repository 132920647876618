import React from 'react'
import './AboutUsText.css';

function AboutUsText() {
  return (
    <>
      <div className='abtus-text'>
        <div> 30 سال تلاش برای جامعه مهندسی ایران </div>

        <div>
          از هنگامی که در 1368 بنیانگذار گروه نرم افزاری سازه به تنهائی اولین کُدهای AutoLISP را در نرم افزار AutoCAD شروع به نوشتن کرد تا امروز که ده ها نفر از همکاران ما در شهرک علمی تحقیقاتی اصفهان از بالاترین تکنیک ها برای توسعه و صادرات  محصولات به بازارهای بین‌الملل استفاده می کنند   <strong>ساخت جهانی بهتر انرژی بخش فراز و فرودهای ما بوده است.  </strong> در تایم لاین زیر مهم ترین مقاطع این پشتوانه ۳۰ ساله را برای شما فهرست کرده‌ایم. در مرور این تاریخچه با ما همراه باشید.          </div>

        <div>
          نکته: تایم لاین زیر بر اساس مقاطع جدید به قدیم از بالا به پایین مرتب شده است.
        </div>

      </div >
    </>
  )
}

export default AboutUsText