import React from 'react'
import AccordinBox from '../../../../PersonalFunction/AccordinBox'

function EmployerImplementationProcess() {

    const Questions = [
        {
            id: 1,
            question: '1. ثبت‌نام و آشنایی با پلتفرم',
            answer:
                <>
                    <strong >ثبت‌نام در EliteZone:  </strong>
                    اطلاعات حساب کاربری و اطلاعات شغلی خود را در پلتفرم ثبت کنید،
                    <br />
                    <br />
                    <strong> آشنایی با سیستم:  </strong>
                    از مستندات، ویدیوهای آموزشی، و راهنمای سیستم استفاده کنید تا با نحوه ثبت سفارش و پیگیری پروژه آشنا شوید.
                </>
        },
        {
            id: 2,
            question: ' 2. تعریف نیازهای پروژه',
            answer:
                <>
                    <strong> مشخص کردن نیازها:  </strong>
                    تمامی نیازهای پروژه، شامل نوع مصالح، مقدار موردنیاز، و استانداردهای کیفیت را با مشاوره با مهندس یا پیمانکار به‌طور دقیق مشخص کنید.
                    <br />
                    <br />
                    <strong> مشخصات پروژه:  </strong>
                    اطلاعات مربوط به محل پروژه، زمان‌بندی اجرا، و جزئیات مربوط به شرایط حمل و نقل و تحویل را در پلتفرم ثبت کنید.
                </>
        },
        {
            id: 3,
            question: ' 3. انتخاب مهندس و پیمانکار',
            answer:
                <>
                    <strong> بررسی رزومه‌ها:  </strong>
                    اگر هنوز مهندس یا پیمانکار انتخب نکرده اید، گزینه‌های مناسب را بررسی و انتخاب  کنید.
                    <br />
                    <br />
                    <strong> برگزاری جلسه مشاوره: </strong>
                    با مهندس و پیمانکار منتخب جلساتی برگزار کنید تا در مورد جزئیات پروژه و نیازها به جمع بندی برسید.
                </>
        },
        {
            id: 4,
            question: ' 4. ثبت و مدیریت سفارش مصالح',
            answer:
                <>
                    <strong> انتخاب تامین‌کنندگان:  </strong>
                    از بین تامین‌کنندگان معتبر در پلتفرم، گزینه‌های مناسب را برای تامین مصالح انتخاب کنید.
                    <br />
                    <br />
                    <strong>  ثبت سفارش:  </strong>
                    سفارش مصالح را با مشخص کردن جزئیات شامل نوع، مقدار، و زمان تحویل ثبت کنید.
                    <strong> پیگیری سفارش:  </strong>
                    وضعیت سفارشات ثبت‌شده را پیگیری کرده و در صورت تاخیر یا مشکل، آن را گزارش کنید.
                </>
        },
        {
            id: 5,
            question: ' 5. نظارت بر پیشرفت پروژه',
            answer:
                <>
                    <strong> بازدیدهای دوره‌ای:  </strong>
                    در صورت امکان، از پروژه بازدید کنید تا از تطابق تحویل مصالح با برنامه مطمئن شوید.
                    <br />
                    <br />
                    <strong> کنترل کیفیت:  </strong>
                    اطمینان حاصل کند که مصالح ساختمانی مطابق با استانداردهای مورد انتظار است.
                </>
        },
        {
            id: 6,
            question: ' 6. مدیریت مالی پروژه',
            answer:
                <>
                    <strong> بودجه‌بندی:  </strong>
                    بودجه کلی پروژه را مشخص کرده و هزینه‌های خرید مصالح را مدیریت کنید.
                    <br />
                    <br />
                    <strong> پرداخت‌ها:  </strong>
                    پرداخت‌ها را مطابق با فاکتورهای صادره به تامین‌کنندگان انجام دهید.
                    <br />
                    <br />
                    <strong> شفافیت مالی:  </strong>
                    رسیدها و فاکتورهای مرتبط با هزینه‌ها را به‌صورت دقیق ثبت و ذخیره کنید.
                </>
        },
        {
            id: 7,
            question: ' 7. رسیدگی به مشکلات و اختلافات',
            answer:
                <>
                    <strong> تعامل موثر:  </strong>
                    در صورت بروز مشکلات یا اختلافات، با مهندس، پیمانکار یا تامین‌کننده وارد تعامل شده و برای حل مشکلات تلاش کنید.
                    <br />
                    <br />
                    <strong> استفاده از پشتیبانیEliteZone :  </strong>
                    در صورت عدم حل مشکلات، از بخش پشتیبانی پلتفرم کمک بگیرید.
                </>
        },

        {
            id: 8,
            question: ' 8. ارزیابی و تکمیل پروژه',
            answer:
                <>
                    <strong> بررسی نهایی:  </strong>
                    پس از اتمام پروژه، تمامی جوانب از جمله کیفیت مصالح و هزینه‌ها را بررسی کنید.
                    <br />
                    <br />
                    <strong> ارائه بازخورد:  </strong>
                    بازخورد خود در مورد عملکرد مهندسان، پیمانکاران و تامین‌کنندگان را در سیستم ثبت کنید.
                    تحویل پروژه: پروژه را به‌صورت رسمی تحویل گرفته و مستندات نهایی را دریافت کنید.
                </>
        },

        {
            id: 9,
            question: ' نکات مهم',
            answer:
                <>
                    <strong> شفافیت در ارتباطات:  </strong>
                    اطمینان حاصل کنید که تمامی اطلاعات به‌صورت شفاف و دقیق به مهندسان، پیمانکاران و تامین‌کنندگان منتقل شود.
                    <br />
                    <br />
                    <strong> انعطاف‌پذیری:  </strong>
                    در مواجهه با شرایط غیرمنتظره، انعطاف‌پذیر باشید و تصمیمات لازم را با هماهنگی تیم اجرایی اتخاذ کنید.
                    <br />
                    <br />
                    <strong>  رعایت اخلاق حرفه‌ای:  </strong>
                    تعامل با تمامی طرف‌ها را با احترام، شفافیت و حرفه‌ای‌گری انجام دهید.
                    <br />
                    <br />
                    این دستورالعمل می‌تواند بر اساس نیازهای خاص EliteZone   تکمیل شود.

                </>
        },
    ];

    return (
        <>
            <div className='ImplementationProcess_Container'>
                <div className='title'>
                    فرآیند اجرایی کارفرمایان
                </div>
                در EliteZone، کارفرما (مالک یا مدیر پروژه ساختمانی) یکی از اصلی‌ترین ذی‌نفعان است که نقش آن در تعیین نیازها، هماهنگی با مهندسان وپیمانکاران، و نظارت بر روند اجرا بسیار حائز اهمیت است. در ادامه، یک دستورالعمل جامع برای کارفرمایان در EliteZone  ارائه شده است:

                <div className='moredetail'>
                    {Questions.map((question, index) => (
                        <span key={index}>
                            <AccordinBox question={question} index={index} />
                        </span>
                    ))}
                </div>
            </div>
        </>
    )
}

export default EmployerImplementationProcess