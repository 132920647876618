import React from 'react';
import AccordinBox from '../../../PersonalFunction/AccordinBox';

function ContractorImplementationProcess() {
    const Questions = [
        {
            id: 1,
            question: ' 1. ثبت‌نام و آشنایی با سیستم',
            answer:
                <>
                    <strong >ثبت‌نام در سیستم: </strong>
                    در اولین مرحله، در پلتفرم EliteZone  به‌عنوان پیمانکار ثبت‌نام کنید و اطلاعات حساب کاربری و اطلاعات شغلی خود را تکمیل نمائید.
                    <br />
                    <br />
                    <strong> آموزش و آشنایی: </strong>
                    ویدیوها، مستندات و موارد آموزشی ارائه شده را مطالعه نمائید تا با امکانات پلتفرم و نحوه کار با آن آشنا شوید.                </>
        },
        {
            id: 2,
            question: '2. ایجاد شبکه مشتریان',
            answer:
                <>
                    <strong> جذب کارفرمایان: </strong>
                    کارفرمایانی را که به مصالح ساختمانی نیاز دارند به استفاده از EliteZone  دعوت کنید.
                    <br />
                    <br />
                    <strong> ارائه مشاوره:  </strong>
                    خدمات مشاوره‌ای اولیه در خصوص نیازهای پروژه و نوع مصالح مورد نیاز را به کارفرما ارائه دهید.
                </>
        },
        {
            id: 3,
            question: '3. برآورد و پیشنهاد مصالح',
            answer:
                <>
                    <strong> بررسی نیازها: </strong>
                    درباره نیازهای پروژه با جزئیات با کارفرما گفتگو کنید.
                    <br />
                    <br />
                    <strong> پیشنهاد مصالح: </strong>
                    پیشنهاد‌های مرتبط با مصالح ساختمانی را با استفاده از دیتابیس EliteZone  ارائه کنید.
                    <br />
                    <br />
                    <strong> برآورد هزینه: </strong>
                    بر اساس اطلاعات موجود در پلتفرم، هزینه‌های تخمینی را محاسبه و به کارفرما اعلام کنید.
                </>
        },
        {
            id: 4,
            question: '4. راهنمائی کارفرما برای ثبت سفارش',
            answer:
                <>
                    موجودی و زمان تحویل، قیمت و نحوه پرداخت مبلغ مصالح را با پنلی که از طریق سیستم در اختیار دارید به کارفرما اطلاع دهید.
                    <br />
                    <br />
                    کارفرما را راهنمائی کنید تا در EliteZone ثبت نام و سفارش نهایی را در سیستم ثبت کند.
                </>
        },
        {
            id: 5,
            question: '5. نظارت بر تحویل مصالح',
            answer:
                <>
                    <strong> پیگیری تحویل: </strong>
                    مراحل تحویل مصالح به محل پروژه را پیگیری و در سیستم ثبت کنید.                    <br />
                    <br />
                    <strong>  کنترل کیفیت: </strong>
                    کیفیت مصالح تحویل داده شده و تطابق آن با مشخصات فنی اعلام ‌شده را در سیستم ثبت کنید.
                </>
        },
        {
            id: 6,
            question: '6. تعامل و ارتقاء شبکه',
            answer:
                <>
                    <strong> بازخوردگیری: </strong>
                    بازخوردهای کارفرمایان را در سیستم ثبت و برای بهبود خدمات خود و EliteZone از آن‌ها استفاده کنید.
                    <br />
                    <br />
                    <strong> گسترش شبکه:  </strong>
                    با کارفرمایان جدید ارتباط بگیرید و پروژه‌های بیشتری را در سیستم ثبت کنید.                </>
        },
        {
            id: 7,
            question: 'نکات مهم',
            answer:
                <>
                    <strong> امتیاز ویژه: </strong>
                    به عنوان یک پیمانکار، می توانید از امتیازات EliteZone برای خرید در پروژه هائی که خود کارفرما هستید استفاده کنید.                    <br />
                    <br />
                    <strong> شفافیت در ارتباطات: </strong>
                    تمامی ارتباطات با کارفرما را شفاف، دقیق و مستند انجام دهید.                    <br />
                    <br />
                    <strong> به‌روز بودن: </strong>
                    اطلاعات خود را درباره محصولات، قیمت‌ها و شرایط بازار به‌روز نگه دارید.                    <br />
                    <br />
                    <strong> رعایت اخلاق حرفه‌ای: </strong>
                    ارائه خدمات با کیفیت و رفتار حرفه‌ای، اعتماد کارفرمایان را به همراه خواهد داشت.
                    <br />
                    <br />
                    این دستورالعمل می‌تواند بر اساس نیازهای خاص EliteZone  تکمیل شود.
                </>
        },
    ];
    return (
        <>
            <div className='ImplementationProcess_Container'>
                <div className='title'>
                    فرآیند اجرایی پیمانکاران
                </div>
                در EliteZone، پیمانکاران نقشی موثر در ارائه مشاوره به کارفرما برای خرید مصالح ساختمانی با کیفیت دارند. دستورالعمل زیر به منظور تعریف وظایف پیمانکاران و نحوه انجام این وظایف تهیه شده است

                <div className='moredetail'>
                    {Questions.map((question, index) => (
                        <span key={index}>
                            <AccordinBox question={question} index={index} />
                        </span>
                    ))}
                </div>
            </div>
        </>
    )
}

export default ContractorImplementationProcess