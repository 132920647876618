import React from 'react'
import Navbar from '../../Home/Components/Navbar'
import PrivacyImg from '../Assets/Privacy.svg';
import AccordinBox from '../../../PersonalFunction/AccordinBox';

function Privacy() {

    const Questions = [
        {
            id: 1,
            question: 'صحت اطلاعات تماس کاربران',
            answer: (
                <>
                    کاربر با ثبت اطلاعات تماس خود، صحت آن را تأیید می‌کند. در صورتی که این اطلاعات کامل یا صحیح نباشد، اِلیت زون می‌تواند برای تأیید نهایی، اطلاعات بیشتری از کاربر درخواست نماید.
                </>
            )
        },
        {
            id: 2,
            question: 'اطلاعات ناشناس و تحلیل داده‌ها',
            answer: (
                <>
                    اِلیت زون ممکن است، با کسب اجازه از کاربران، اطلاعاتی غیرمستقیم و ناشناس از جمله نوع دستگاه، سیستم‌عامل، شناسه تبلیغاتی و تاریخچه جست‌وجوها را جمع‌آوری کند. این داده‌ها به منظور بهبود تجربه کاربری، شناسایی مشکلات و ارائه پیشنهادات بهتر به کاربران مورد استفاده قرار می‌گیرند و هیچ پیوندی با هویت شخصی کاربران ندارند.
                </>
            )
        },
        {
            id: 3,
            question: 'استفاده از کوکی‌ها',
            answer: (
                <>
                    برای ارائه تجربه‌ای شخصی و بهینه، اِلیت زون از کوکی‌ها استفاده می‌کند که به سایت امکان می‌دهد بازدیدهای شما را ثبت و تنظیمات لازم را برای شما ذخیره کند. شما می‌توانید در صورت تمایل، کوکی‌ها را از طریق تنظیمات مرورگر خود غیرفعال نمایید؛ هرچند ممکن است این کار بر عملکرد برخی از ویژگی‌های سایت تاثیر بگذارد.
                </>
            )
        },
        {
            id: 4,
            question: 'اطلاع‌رسانی از رویدادها و پیشنهادات ویژه',
            answer: (
                <>
                    اِلیت زون برای اطلاع‌رسانی درباره رویدادها و پیشنهادات ویژه ممکن است از طریق ایمیل یا پیامک با کاربران در تماس باشد. اگر کاربران علاقه‌ای به دریافت این پیام‌ها نداشته باشند، می‌توانند اشتراک را لغو کنند.
                </>
            )
        },
        {
            id: 5,
            question: 'شماره‌های رسمی پیامکی',
            answer: (
                <>
                    پیامک‌های اِلیت زون فقط از طریق شماره‌های رسمی ارسال می‌شوند. هرگونه پیامک با نام اِلیت زون که از شماره‌های دیگر ارسال شود، تخلف محسوب شده و در صورت دریافت چنین پیامی، کاربران می‌توانند آن را به پشتیبانی اِلیت زون اطلاع دهند.
                </>
            )
        },
        {
            id: 6,
            question: 'بررسی و مدیریت نظرات کاربران',
            answer: (
                <>
                    اِلیت زون این حق را برای خود محفوظ می‌دارد که نظرات کاربران را برای رعایت قوانین ویرایش و بازبینی کند. در صورتی که نظرات حاوی محتوای غیرمجاز باشند، ممکن است از اطلاعات کاربران برای پیگیری قانونی استفاده شود. کاربران با ثبت نظر خود، به اِلیت زون اجازه ویرایش و استفاده از نظرات در چارچوب قوانین سایت را می‌دهند.
                </>
            )
        },
        {
            id: 7,
            question: 'حفظ امنیت حساب کاربری',
            answer: (
                <>
                    حفظ و نگهداری از نام کاربری و رمز عبور بر عهده کاربران است. در صورت تغییر شماره تماس، کاربران موظف هستند که اطلاعات پروفایل خود را به‌روز کنند. مسئولیت هرگونه خسارت ناشی از عدم رعایت این نکات بر عهده خود کاربران خواهد بود.
                </>
            )
        },
        {
            id: 8,
            question: 'حفظ محرمانگی اطلاعات و رعایت قوانین',
            answer: (
                <>
                    اِلیت زون متعهد به حفظ اطلاعات شخصی کاربران است و این اطلاعات را به شخص یا سازمان دیگری منتقل نمی‌کند، مگر اینکه طبق قوانین و یا با حکم مراجع قانونی ملزم به انجام این کار باشد. در چنین شرایطی، مسئولیت جبران خسارت متوجه اِلیت زون نخواهد بود و کاربران با استفاده از خدمات سایت، این شرایط را می‌پذیرند.
                </>
            )
        },
        {
            id: 9,
            question: 'جمع‌آوری اطلاعات از طریق IP  و کوکی‌ها',
            answer: (
                <>
                    همانند سایر سایت‌ها، اِلیت زون برای ارائه خدمات بهتر از IP و کوکی‌ها استفاده می‌کند. این اطلاعات به سایت امکان می‌دهد که تنظیمات مربوط به کاربر را ذخیره و تجربه کاربری بهتری فراهم کند.
                </>
            )
        },
        {
            id: 10,
            question: 'لغو اشتراک از خبرنامه‌ها',
            answer: (
                <>
                    کاربرانی که مایل به دریافت خبرنامه یا ایمیل‌های اِلیت زون نیستند، می‌توانند از طریق لینک لغو اشتراک در انتهای ایمیل‌ها، این امکان را غیرفعال کنند.
                </>
            )
        },
        {
            id: 11,
            question: 'پشتیبانی و تماس با اِلیت زون',
            answer: (
                <>
                    در صورت نیاز به اطلاعات بیشتر یا درخواست پشتیبانی، کاربران می‌توانند از طریق بخش تماس با ما در سایت، با تیم پشتیبانی اِلیت زون ارتباط برقرار کنند.
                </>
            )
        },
    ];

    return (
        <>
            <Navbar />

            <div className='Terms_Container'>

                <div className='Terms_Img'>
                    <img src={PrivacyImg} alt='img' />
                </div>

                <div className='Terms_Title'>
                    حریم خصوصی
                </div>

                <div className='Terms_Body'>

                    <div className='Terms_Body_Text'>
                        اِلیت زون به حفظ حریم خصوصی کاربران خود متعهد است و با رعایت اصول امنیتی و قوانین جاری، اطلاعاتی را که برای ارائه خدمات بهتر به آن‌ها نیاز دارد، گردآوری می‌کند. این اطلاعات شامل جزئیاتی مانند آدرس، شماره تماس و ایمیل است و برای ارائه خدمات ضروری می‌باشد. از آن‌جایی که تمامی فعالیت‌های اِلیت زون مطابق با قوانین تجارت الکترونیک انجام می‌شود، ورود اطلاعاتی مانند نام و کد ملی برای کاربران حقیقی و کد اقتصادی و شناسه ملی برای کاربران سازمانی لازم است. لازم به ذکر است که اطلاعات تماس ثبت‌شده توسط کاربر تنها راه ارتباط رسمی با او خواهد بود.
                    </div>

                    <div className='Terms_Body_QuestionBox'>
                        {Questions.map((question, index) => (
                            <span key={index}>
                                <AccordinBox question={question} index={index} />
                            </span>
                        ))}
                    </div>

                </div>

            </div>
        </>
    )
}

export default Privacy